import React from "react"
import SEO from "../components/layout/seo"
import blockResolver from "../components/resolvers/blockResolver"
import { graphql } from 'gatsby'

const DefaultPage = (props) => {

  const { pageContext, data } = props
  const { strapi } = data
  const { page } = strapi
  const { Obsah } = page

  return(
    <>
      <SEO 
        title={pageContext.Titulek} 
        url={typeof window !== 'undefined' && window && window.location.href} 
        updated={page.updatedAt} 
        created={page.createdAt} 
        schema={true} 
        lang={pageContext.languageCode === "" ? "cs" : pageContext.languageCode}
      />
      {Obsah.map((o, i) => blockResolver(o, i))}
    </>
  )
}

export const query = graphql`
  query DefaultPageQuery($id: ID!) {
    strapi {
      page(id: $id) {
        id
        Titulek
        slug
        updatedAt
        createdAt
        Obsah {
          ... on Strapi_ComponentSpecialniSekceHeroSekce {
            ...SpecialniSekceHeroSekce
          }
          ... on Strapi_ComponentSekceTextMediaSekce {
            ...SekceTextMediaSekce
          }
          ... on Strapi_ComponentSekceKlasickaSekce {
            ...SekceKlasickaSekce
          }
          ... on Strapi_ComponentSpecialniSekceSluzby {
            ...SpecialniSekceSluzby
          }
          ... on Strapi_ComponentSpecialniSekceTymPrehled {
            ...TymPrehled
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default DefaultPage